<template>
  <div>
    <div class="card card-custom gutter-b" v-if="user">
      <!--begin::Header-->
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">{{ $t("users.heading.overview") }}</h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("users.subheading.overview") }} </span>
        </div>
      </div>
      <!--end::Header-->
      <div class="card-body row">
          <div class="col-6">
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class=" mr-2">{{ $t("label.login") }}:</span>
                <span class="font-weight-bolder" v-if="user.login">{{ user.login }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class=" mr-2">{{ $t("label.name") }}:</span>
                <span class="font-weight-bolder" v-if="user.full_name">{{ user.full_name }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class=" mr-2">{{ $t("label.phone") }}:</span>
                <span class="font-weight-bold" v-if="user.phone">{{ user.phone }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class=" mr-2">{{ $t("label.email") }}:</span>
                <span class="font-weight-bold" v-if="user.email">{{ user.email }}</span>
              </div>
          </div>
          <div class="col-6 text-right">
            <div class="py-0 mr-3">
              <div class="image-input image-input-outline" id="kt_profile_avatar">
                <div class="image-input-wrapper">
                  <img :src="'data:image/jpeg;base64,'+user.avatar.avatar_base_64" style="max-width: 100%; max-height: 100%;" v-if="user.avatar">
                  <inline-svg src="/media/svg/avatars/001-boy.svg" class="align-self-end" v-else/>
                </div>
                <label
                  class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Change avatar"
                  v-if="!user.avatar"
                  v-b-modal.add-avatar-modal
                >
                  <i class="fa fa-pen icon-sm text-muted"></i>                  
                </label>
                <span
                  class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="cancel"
                  data-toggle="tooltip"
                  title="Cancel avatar"
                >
                  <i class="ki ki-bold-close icon-xs text-muted"></i>
                </span>
                <span
                  class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="remove"
                  data-toggle="tooltip"
                  title="Remove avatar"
                  @click.prevent="removeAvatar"
                  v-if="user.avatar"
                >
                  <i class="ki ki-bold-close icon-xs text-muted"></i>
                </span>
              </div>
            </div>
          </div>
      </div>
      <div class="card-footer row">
            <div class="col-4">
              <span class="d-block font-weight-bold mb-4">{{ $t("users.title.created_date") }}</span>
              <span class="btn btn-light-primary btn-sm font-weight-bold btn-upper btn-text">{{ user.created_at_formated }}</span>
            </div>
            <div class="col-4">
              <span class="d-block font-weight-bold mb-4">{{ $t("users.title.last_activity") }}</span>
              <span class="btn btn-sm font-weight-bold btn-upper btn-text">
                <span v-if="user.last_activity_formated">
                  <small class="fa fa-circle text-success mr-2" ></small>{{ user.last_activity_formated }}
                </span>
                <span v-else>
                  <small class="fa fa-circle text-danger mr-2" ></small>{{ $t("users.title.no_logged") }}
                </span>
              </span>
            </div>
            <!--end: Item-->

            <!--begin: Item-->
            <div class="d-flex align-items-center">
              <span class="mr-4">
                <i class="flaticon-lock icon-2x text-muted font-weight-bold"></i>
              </span>
              <div class="d-flex flex-column text-dark-75">
                <span class="font-weight-bolder font-size-sm">{{ $t("label.pin") }}</span>
                <span class="font-weight-bolder font-size-h5" v-if="user.pincode">
                  <span class="text-dark-50 font-weight-bold"></span>{{ user.pincode }}
                </span>
                <span class="font-weight-bolder text-muted" v-else>{{ $t("users.title.not_set") }}</span>
    
              </div>
            </div>
            <!--end: Item-->
        </div>
    </div>
    <div class="card card-custom" v-if="!loading">
        <!--begin::Header-->
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">{{ $t("users.heading.roles_asign") }}</h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("users.subheading.roles_asign") }}</span>
        </div>
      </div>
      <!--end::Header-->
        <div class="card-body">
          <div>
            <b-badge variant="primary mr-3"
            v-for="(role, index) in user.roles"
            :key="index">
            {{ role.name }}
            </b-badge>
          </div>
        </div>
      </div>

  
      <add-avatar type="profil">
      </add-avatar>

    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";
import { UPDATE_CURRENT_DATA } from "@/core/services/store/auth.module";
import AddAvatar from "@/view/content/AddAvatar";

export default {
  name: "user_overview",
  data() {
    return {
      loading: false,
      roles: null,
      avatar: null,
    };
  },
  components: {
    AddAvatar
  },
  props: {
    user: null,
  },
  metaInfo () { 
      return { title: this.$t("users.meta.subtitle.overview")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("users.meta.title_profile"), subtitle: this.$t("users.meta.subtitle.overview") }]);
  },
  methods: {
    removeAvatar(){
      this.loading = true;
      ApiService.apiPost("/profil/remove-avatar")
        .then(() => {
            this.loading = false;
            this.$store.dispatch(UPDATE_CURRENT_DATA);
        }).catch(error => {
            checkErrors(error);
      });
      this.$parent.getUser();
    }
  }
};
</script>
